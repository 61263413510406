import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

import Flex from './flex.vue'
import FlexItem from './flexItem.vue'
import TablePlus from './tablePlus.vue'
import TotalCount from './totalCount.vue'
import SearchBox from './searchBox.vue'
const components = {
  Flex,
  FlexItem,
  TablePlus,
  TotalCount,
  SearchBox
}

const registeredComponents = (app) => {
  for (const k in components) {
    app.component(`xm${k}`, components[k])
  }
  registeredSingleton(app)
}

function registeredSingleton () {
  _loading()
  _message()
  _alert()
  _confirm()
}

function _message () {
  window.$message = (options) => {
    options = {
      ...options
    }
    return ElMessage(options)
  }
}
function _loading () {
  window.$loading = (options) => {
    options = {
      text: 'Loading...',
      background: 'rgba(0, 0, 0, 0.8)',
      ...options
    }
    return ElLoading.service(options)
  }
}
function _alert () {
  window.$alert = (message, title, options) => {
    title = title || '提示'
    options = {
      confirmButtonText: '确定',
      ...options
    }
    return ElMessageBox.alert(message, title, options)
  }
}
function _confirm () {
  window.$confirm = (message, title, options) => {
    title = title || '提示'
    options = {
      cancelButtonText: '取消',
      confirmButtonText: '确定',
      ...options
    }
    return ElMessageBox.confirm(message, title, options)
  }
}

export default registeredComponents
