<template>
  <div :style="{'flex':flex}" class="xm-flex-item">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    flex: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="scss" scoped>
.xm-flex-item {
  position: relative;
}
</style>
