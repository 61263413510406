<template>
  <div class="xm-table-plus">
    <!-- 表格 -->
    <el-table :data="table.list" height="auto" border stripe @cell-click="cellClick">
      <slot></slot>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      v-model:currentPage="pagination.page"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizes"
      layout="total, sizes, prev, pager, next, jumper"
      :total="table.totalCount"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>
<script>
import { ElTable, ElPagination } from 'element-plus'
import { reactive } from 'vue'
export default {
  components: {
    ElTable, ElPagination
  },
  props: {
    queryData: {
      type: Function
    }
  },
  emits: ['cellClick'],
  setup (prop, { emit }) {
    // #region  表格
    const table = reactive({
      list: [],
      totalCount: 0
    })
    function reloadCurrent () {
      if (prop.queryData) {
        query()
      }
    }
    function reload () {
      if (prop.queryData) {
        pagination.page = 1
        query()
      }
    }
    async function query () {
      const res = await prop.queryData(pagination.page, pagination.pageSize)
      table.list = res.list || []
      table.totalCount = res.totalCount || 0
    }
    /**
     * 判断位置 追加_isFirst _isLast位置标志位
     */
    function judgePosition (list = [], totalCount = 0) {
      if (list.length === 0 || totalCount === 0) {
        return
      }
      if (pagination.page === 1) {
        list[0]._isFirst = true
      }
      if (totalCount / pagination.pageSize <= pagination.page) {
        list[list.length - 1]._isLast = true
      }
    }
    // #endregion

    // #region  分页
    const pagination = reactive({
      page: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50, 100]
    })
    const handleSizeChange = (val) => {
      pagination.pageSize = val
      reloadCurrent()
    }
    const handleCurrentChange = (val) => {
      pagination.page = val
      reloadCurrent()
    }

    const cellClick = (row, column, cell, event) => {
      emit('cellClick', row, column, cell, event)
    }
    // #endregion

    return {
      table,
      pagination,
      handleSizeChange,
      handleCurrentChange,
      judgePosition,
      reload,
      reloadCurrent,
      cellClick
    }
  }
}
</script>
<style lang="scss" scoped>
$pagination-height:50px;
.xm-table-plus {
  position: absolute;
  height: 100%;
  width: 100%;
  .el-table {
    height: calc(100% - $pagination-height) !important;
  }
  :deep .el-table--scrollable-y .el-table__body-wrapper {
    overflow-y: auto;
  }
  :deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: auto;
  }
  .el-pagination {
    height: $pagination-height;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
