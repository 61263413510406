<template>
  <div class="xm-total-count">
    <slot></slot>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.xm-total-count{
  font-weight: bold;
  height: 50px;
  line-height: 50px;
}
</style>
