<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>
<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { ElConfigProvider } from 'element-plus'

export default {
  components: {
    ElConfigProvider
  },

  setup () {
    return {
      locale: zhCn
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
