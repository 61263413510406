import { createStore } from 'vuex'

export default createStore({
  state: {
    breadcrumb: [] // banner顶部面包屑
  },
  mutations: {
    /**
     * 面包屑
     */
    pushBreadcrumb (state, crumb) {
      state.breadcrumb.push(crumb)
    },
    spliceBreadcrumb (state, index) {
      state.breadcrumb.splice(index)
    },
    replaceBreadcrumb (state, breadcrumb) {
      state.breadcrumb = breadcrumb
    }
  },
  actions: {
  },
  modules: {
  }
})
