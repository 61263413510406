<template>
  <div class="flex">
    <slot></slot>
  </div>
</template>
<script>
export default {
  setup () {

  }
}
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
