<template>
  <div class="xm-search-box">
    <slot></slot>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.xm-search-box {
  margin-top: 10px;
  :deep .el-input {
    width: auto;
  }
  :deep .el-input {
    margin-right: 20px;
  }
  :deep .el-date-editor {
    margin-right: 20px;
  }
}
</style>
