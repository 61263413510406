import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import 'element-plus/dist/index.css'
import '@/common/css/base.scss'
import '@/common/css/element-plus/index.scss'

import registeredComponents from '@/components/index.js' // 注册自定义组件+单例(window.$alert/$confirm/$message等)

const app = createApp(App)

registeredComponents(app)
app.use(router)
app.use(store)

app.mount('#app')
