import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
  {
    path: '/',
    name: 'website',
    component: () => import('@/views/website/index.vue')
  },
  {
    path: '/editor',
    name: 'editor',
    component: () => import('@/views/institution-management/editor.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/institution/choose-enterprise',
    name: 'chooseEnterprise',
    component: () => import('@/views/institution/choose-enterprise/list.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    children: [
      /**
       * 管理后台相关路由
       */
      {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/institution-management/settings.vue'),
        meta: {
          name: '设置'
        }
      },
      {
        path: '/goods/list',
        name: 'goods',
        component: () => import('@/views/institution-management/goods/list.vue'),
        meta: {
          name: '商品'
        }
      },
      {
        path: '/enroll/list',
        name: 'enroll',
        component: () => import('@/views/institution-management/enroll/list.vue'),
        meta: {
          name: '订单'
        }
      },
      {
        path: '/desc-template',
        name: 'descTemplate',
        component: () => import('@/views/institution-management/desc-template/list.vue'),
        meta: {
          name: '图文介绍'
        }
      },
      {
        path: '/desc-template/edit',
        name: 'descTemplate_edit',
        component: () => import('@/views/institution-management/desc-template/edit.vue'),
        meta: {
          name: '编辑图文'
        }
      },
      /**
       * 运营后台相关路由
       */
      {
        path: '/manage-institution',
        name: 'manageInstitution',
        component: () => import('@/views/manage/institution/list.vue'),
        meta: {
          name: '企业管理'
        }
      },
      {
        path: '/manage-platform-withdraw-record-list',
        name: 'managePlatformWithdrawRecordList',
        component: () => import('@/views/manage/platform-withdraw-record/list.vue'),
        meta: {
          name: '提现管理'
        }
      },
      {
        path: '/manage-platform-index',
        name: 'managePlatformIndex',
        component: () => import('@/views/manage/platform-index/list.vue'),
        meta: {
          name: '小萌私域首页'
        }
      },
      {
        path: '/manage-platform-setmeal-order',
        name: 'managePlatformSetmealOrder',
        component: () => import('@/views/manage/platform-setmeal-order/list.vue'),
        meta: {
          name: '套餐订单'
        }
      },
      {
        path: '/manage-mini-template',
        name: 'manageMiniTemplate',
        component: () => import('@/views/manage/mini-template/list.vue'),
        meta: {
          name: '小程序模板'
        }
      },
      {
        path: '/manage-goods-mini-template',
        name: 'manageGoodsMiniTemplate',
        component: () => import('@/views/manage/goods-mini-template/list.vue'),
        meta: {
          name: '商品模板'
        }
      },
      {
        path: '/manage-desc-template',
        name: 'manageDescTemplate',
        component: () => import('@/views/manage/desc-template/list.vue'),
        meta: {
          name: '图文模板'
        }
      },
      {
        path: '/manage-desc-group-template',
        name: 'manageDescGroupTemplate',
        component: () => import('@/views/manage/desc-group-template/list.vue'),
        meta: {
          name: '图文组合模板'
        }
      },
      {
        path: '/manage-banner-group-template',
        name: 'manageBannerGroupTemplate',
        component: () => import('@/views/manage/banner-group-template/list.vue'),
        meta: {
          name: '宣传栏组合模板'
        }
      },
      {
        path: '/manage-goods-share-poster-template',
        name: 'manageGoodsSharePosterTemplate',
        component: () => import('@/views/manage/goods-share-poster-template/list.vue'),
        meta: {
          name: '商品海报模板'
        }
      },
      {
        path: '/manage-article-share-poster-template',
        name: 'manageArticleSharePosterTemplate',
        component: () => import('@/views/manage/article-share-poster-template/list.vue'),
        meta: {
          name: '文章海报模板'
        }
      },
      {
        path: '/manage-marketing-msg-poster-template',
        name: 'manageMarketingMsgPosterTemplate',
        component: () => import('@/views/manage/marketing-msg-poster-template/list.vue'),
        meta: {
          name: '营销消息模板'
        }
      },
      {
        path: '/manage-assist-msg-poster-template',
        name: 'manageAssistMsgPosterTemplate',
        component: () => import('@/views/manage/assist-msg-poster-template/list.vue'),
        meta: {
          name: '助力消息模板'
        }
      },
      {
        path: '/manage-thirdparty-template',
        name: 'manageThirdpartyTemplate',
        component: () => import('@/views/manage/thirdparty-template/list.vue'),
        meta: {
          name: '小程序版本'
        }
      }
    ]
  },
  {
    path: '/:w*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 开启页面加载进度
  NProgress.start()
  next()
})

router.afterEach((to) => {
  matched(to.path)
  NProgress.done()
})

function matched (path) {
  const breadCrumb = []
  const route = routes.find(item => item.path === '/home').children
  route.forEach(item => {
    if (path.startsWith(item.path) && item.meta && item.meta.name) {
      breadCrumb.push({
        path: item.path,
        title: item.meta.name
      })
    }
  })
  store.commit('replaceBreadcrumb', breadCrumb)
}
export default router
